import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f706e2d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-shell" }
const _hoisted_2 = { class: "view-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_side_bar = _resolveComponent("app-side-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_side_bar),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}
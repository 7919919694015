import { createI18n } from 'vue-i18n'
import globalMessage from '@/locales/fr/global/global.json'
import homeMessage from '@/locales/fr/home/home.json'
import programmeMessage from '@/locales/fr/programme/programme.json'

// Initialize messages object
const messages = {
  fr: {
    global: globalMessage,
    home: homeMessage,
    programme: programmeMessage
  }
}

const i18n = createI18n({
  legacy: false,
  locale: 'fr',
  messages: {
    ...messages
  }
})

export default i18n

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import programmeRoutes from '@/modules/programme/router/programmeRoutes'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/conditions',
    name: 'conditions',
    component: () => import(/* webpackChunkName conditionsView */ '@/views/ConditionsView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName notFoundView */ '@/views/NotFoundView.vue')
  },
  ...programmeRoutes
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

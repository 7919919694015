import { RouteRecordRaw } from 'vue-router'

const programmeRoutes: Array<RouteRecordRaw> = [
  {
    path: '/programmes',
    name: 'programmes',
    component: () => import(/* webpackChunkName programmesView */ '@/modules/programme/views/ProgrammeList.vue')
  }
]

export default programmeRoutes
